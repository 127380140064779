<template>
  <div>
    <div class="head_section">
      <h2 id="dkBody" class="tit_subdesc">
        <span class="txt_number">구매요청번호 : {{ docNo }}</span>
        <span class="txt_info">{{ _.unescape(model.title) }}</span>
      </h2>
      <!--      <div class="pos_right">-->
      <!--        <Radio-->
      <!--          name="radioPublic"-->
      <!--          title="문서 공개 여부"-->
      <!--          :dataList="DOC_PUBLIC_RADIO_LIST"-->
      <!--          :selectedId.sync="selectedRadioId"-->
      <!--        />-->
      <!--      </div>-->
    </div>
    <!-- 결재현황 -->
    <div class="section_g">
      <PayStatus :approval-line="approvalLine" :doc-no="docNo" :doc-flag="docFlag" />
    </div>
    <!-- 승인 반려 코멘트 -->
    <RejectComment v-if="whetherExistComment" :approval-line="approvalLine" />
    <!-- 구매요청 기본정보 -->
    <DraftReqeustInfo :draftData="itemData" />
    <!-- PdfViewer -->
    <PdfViewer v-if="itemData.targetPk" :pdfInfoObj="itemData.pdfHistoryResult" />

    <!-- 첨부파일 -->
    <DraftFile :attachFileList.sync="model.attachFileList" />
    <div class="wrap_btn type_bottom">
      <div class="pos_left">
        <button type="button" class="btn_large btn_fourthly" @click.prevent="gotoList">목록</button>
      </div>
    </div>
  </div>
</template>

<script>
import Radio from "@/_approval/components/common/radio/Radio";
import PayStatus from "@/_approval/components/shared/view/PayStatus";
import RejectComment from "@/_approval/components/shared/view/RejectComment";
import DraftReqeustInfo from "@/_approval/components/payment/view/DraftReqeustInfo";
import DraftPurchaseReqeustInfo from "@/_approval/components/purchaseDraft/view/DraftPurchaseReqeustInfo";
import DraftPurchaseGoodsList from "@/_approval/components/purchaseDraft/view/DraftPurchaseGoodsList";
import DraftPurchasePayment from "@/_approval/components/purchaseDraft/view/DraftPurchasePayment";
import DraftFile from "@/_approval/components/purchaseDraft/view/DraftFile";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommToastMixin } from "@/_approval/mixins/common/commToast.js";

import PurchaseRequestDetailMixin from "@/_approval/components/shared/view/PurchaseRequestDetailMixin";
// import DraftWriteModel from '@/_approval/pages/purchaseDraft/write/DraftWriteModel';
import DraftWriteModel from "@/_approval/pages/payment/write/DraftWriteModel";
import commDocs from "@/_approval/mixins/common/commDocs";

import PdfViewer from "@/components/shared/PdfViewer";

import ApiService from "@/services/ApiService";
import {
  assetType,
  approvalStatus,
  aprvlLineType,
  docStatus,
  targetGubun,
} from "@/_approval/const/const";

export default {
  name: "CcIngDetail",
  components: {
    Radio,
    PayStatus,
    RejectComment,
    DraftPurchaseReqeustInfo,
    DraftPurchaseGoodsList,
    DraftPurchasePayment,
    DraftFile,
    PdfViewer,

    DraftReqeustInfo,
  },
  mixins: [CommLayerMixin, CommToastMixin, PurchaseRequestDetailMixin, commDocs],
  data() {
    return {
      approvalLine: [],
      payAccountId: "",
      docNo: this.$route.params.id,
      draftFlag: "",
      remark: "",
      paymentList: [],
      invoiceList: [],
      rejectPaymentIdList: [],
      viewerList: [],
      apprReason: "",
      subject: "",
      mileageFlag: false,
      assetApply: {},
      model: new DraftWriteModel(),
      assetType: assetType,
      docFlag: "RI",
      itemData: {},
    };
  },
  created() {
    this.getData();
    this.updateRead();
  },

  methods: {
    async getData() {
      this.itemId = this.$route.params.id;
      const path = `${this.$apiPath.APRVL_DOC_LIST}/STD/${this.itemId}`;
      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;

      this.itemData = data;
      this.docNo = data.docNo;
      this.targetGubun = data.targetGubun;
      this.approvalLine = data.aprvlLineList;
      this.model = data;
      return;
    },
    gotoList() {
      this.$router.push(this.$paymentRouterPath.PAYMENT_CC_ING);
    },

    async updateRead() {
      const { docStatus, aprvlStatus } = this.docFlagToCode(this.docFlag);

      const params = {
        targetGubun: targetGubun.STANDARD,
        docNoList: [this.docNo],
        readYn: true,
        docStatus,
        aprvlStatus,
      };

      // console.log('params', params);

      const res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_READ, params);
    },
  },
};
</script>
